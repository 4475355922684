<template>
  <article id="post-223" class="ankuendigung">
    <div class="post-img"></div>
    <header class="entry-header">
      <h2 class="entry-title">
        <router-link :to="`/ankuendigungen/${slug}`" rel="bookmark">{{
          title
        }}</router-link>
      </h2>
      <span class="posted-on"
        >Veröffentlicht am
        <time class="entry-date published" :datetime="publishedAt">{{
          publishDate
        }}</time>
      </span>
    </header>

    <div class="excerpt-content">
      <p>
        {{ truncatedExcerpt }}
        <br /><router-link :to="`/ankuendigungen/${slug}`" rel="nofollow"
          >Mehr lesen</router-link
        >
      </p>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    excerpt: {
      type: String,
    },
    publishedAt: {
      type: String,
    },
    slug: {
      type: String,
    },
  },
  computed: {
    truncatedExcerpt() {
      return this.excerpt.length < 250
        ? this.excerpt
        : this.excerpt.slice(0, 250) + "...";
    },
    publishDate() {
      return new Date(this.publishedAt).toLocaleDateString("de", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
    },
  },
};
</script>

<style></style>
