<template>
  <main id="main" class="site-main mt8">
    <article v-if="post">
      <header class="entry-header">
        <h1 class="entry-title">{{ post.title }}</h1>
        <div class="entry-meta">
          <span class="posted-on"
            >Veröffentlicht am
            <time class="entry-date published" :datetime="post.publishedAt">{{
              formattedDate(post.publishedAt)
            }}</time>
          </span>
        </div>
      </header>
      <div class="entry-content"><PortableText :content="post.content" /></div>
      <div class="back-btn">
        <router-link to="/ankuendigungen/" class="button">
          <FontAwesomeIcon :icon="icon" /> Zurück</router-link
        >
      </div>
    </article>
    <div v-else>
      <p>Fehler 404 - Die von Ihnen angeforderte Seite wurde nicht gefunden</p>
    </div>
  </main>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ref, onMounted } from "vue";
import PortableText from "../components/PortableText.vue";
import useSanity from "../use/useSanity";
import { useRoute } from "vue-router";
export default {
  components: { PortableText, FontAwesomeIcon },
  setup() {
    const route = useRoute();
    const { getPost } = useSanity();
    const post = ref([]);

    onMounted(async () => {
      post.value = await getPost(route.params.slug);
    });

    return { post };
  },
  data() {
    return {
      icon: faArrowLeft,
    };
  },
  methods: {
    formattedDate(date) {
      return new Date(date).toLocaleDateString("de", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
    },
  },
};
</script>
