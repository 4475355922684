<template>
  <main id="main" class="site-main mt8 ankuendigungen">
    <h1 class="page-title">Ankündigungen</h1>
    <PostPreview v-for="post of posts" :key="post.slug" v-bind="post" />
  </main>
</template>
<script>
import { ref, onMounted } from "vue";
import useSanity from "../use/useSanity";
import PostPreview from "../components/PostPreview.vue";

export default {
  components: { PostPreview },
  setup() {
    const { getPosts } = useSanity();
    const posts = ref([]);

    onMounted(async () => {
      posts.value = await getPosts();
    });

    return { posts };
  },
};
</script>
<style lang="scss">
@use "../styles/variables" as vars;

.entry-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  h2,
  h1 {
    margin: 0;
    margin-right: 1rem;
  }

  .posted-on {
    font-size: 0.8rem;
    font-style: italic;
    line-height: 2rem;
  }
}

@media only screen and (min-width: 450px) {
  .post-img {
    float: left;

    img {
      margin: 0 2rem 1.5rem 0;
    }
  }
}

@media only screen and (max-width: 450px) {
  .ankuendigung {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: justify;

    img {
      //  max-width: 12rem;
      margin-bottom: 1rem;
    }
  }
}

.divider {
  box-shadow: 0 0.25rem 0.25rem -2px vars.$light-grey;
  width: 100%;
  border: vars.$white 3px solid;
  margin: 0 -5px;
}

.ankuendigung {
  &:first-of-type {
    padding-top: 0.5rem;
  }

  display: block;
  padding: 2rem 0 1rem;
  max-width: 45rem;

  img {
    max-width: 11rem;
  }

  img.attachment-thumbnail {
    margin: auto;
  }

  .flex-element {
    display: flex;
    flex-direction: column;
    min-width: 10.5rem;
    max-width: 35rem;
    padding: 0 1rem;

    &:first-child {
      padding-left: 0;
    }
  }

  .excerpt-content {
    display: flex;
    p:first-of-type {
      margin-top: 0;
    }
  }

  .posted-on {
    line-height: 2.5;
  }
}
</style>
